<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: Account bar -->
    <!-- <div class="topbar-item">
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="btn btn-dropdown btn-lg mr-1 text-decoration-none font-weight-normal text-dark"
        right
        no-flip
      >
        <template v-slot:button-content>
          Account:
          {{ currentAccount || getCurrentAccount }}
        </template>
        <b-dropdown-text tag="div" style="width: 175px;">
          <DropdownAccount
            v-on:account-changed="onAccountChanged"
          ></DropdownAccount>
        </b-dropdown-text>
      </b-dropdown>
    </div> -->
    <!--end: Account bar -->

    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages,
      currentAccount: ""
    };
  },
  components: {
    KTQuickUser
  },
  created() {
    // this.unsubscribe = this.$store.subscribe((mutation, state) => {
    //   if (mutation.type === PAGE_SET_LOADING) {
    //     if (!DialogService.dialog) {
    //       DialogService.init();
    //     }
    //     if (state.page.pageLoading && !DialogService.checkIsShown()) {
    //       DialogService.show();
    //     }
    //     if (!state.page.pageLoading && DialogService.checkIsShown()) {
    //       DialogService.hide();
    //     }
    //   }
    // });
  },
  //beforeDestroy() {
  //this.unsubscribe();
  //},
  methods: {},
  computed: {
    getCurrentAccount() {
      return this.onAccountChanged();
    }
  }
};
</script>
