<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="$can($roles.TECHNICAL_DASHBOARD)"
      to="/technical-dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-analytics"></i>
          <span class="menu-text">Technical Dashboard</span>
        </a>
      </li>
    </router-link>
    <li class="menu-section">
      <h4 class="menu-text">Teams</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <router-link
      v-if="
        $can($roles.GROUP_NRQL) ||
        $can($roles.GROUP_SMS) ||
        $can($roles.GROUP_FINANCE_PRICING_UPLOAD) ||
        $can($roles.GROUP_MY_INSIGHTS) ||
        $can($roles.GROUP_SERVICE_MANAGEMENT_REPORTS) ||
        $can($roles.GROUP_MSC) ||
        $can($roles.GROUP_SALES_SHAREPOINT_XLSB_UPLOAD) ||
        $can($roles.GROUP_SALES_DYNAMICS_CSV_UPLOAD)
      "
      to="/group/navigation-menu"
      v-slot="{ href, navigate }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        v-bind:class="{
          'menu-item-active': hasActiveChildren('/group')
        }"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-network"></i>
          <span class="menu-text">Group</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="
        $can($roles.BE_CRITICALS) ||
        $can($roles.BE_AVAILABILITY_REPORTS) ||
        $can($roles.BE_SERVICE_MANAGEMENT_REPORTS)
      "
      to="/be/navigation-menu"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon">BE</i>
          <span class="menu-text">Belgium</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/bg/navigation-menu"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon">BG</i>
          <span class="menu-text">Bulgaria</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/dk/navigation-menu"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon">DK</i>
          <span class="menu-text">Denmark</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/nl/navigation-menu"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon">NL</i>
          <span class="menu-text">The Netherlands</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="$can($roles.IO_ACCOUNTS) || $can($roles.IO_CONTACTS)"
      to="/integration-office/navigation-menu"
      v-slot="{ href, navigate }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        v-bind:class="{
          'menu-item-active': hasActiveChildren('/integration-office')
        }"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-squares-4"></i>
          <span class="menu-text">Integration Office</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
