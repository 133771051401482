var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])])])]}}])}),(_vm.$can(_vm.$roles.TECHNICAL_DASHBOARD))?_c('router-link',{attrs:{"to":"/technical-dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-analytics"}),_c('span',{staticClass:"menu-text"},[_vm._v("Technical Dashboard")])])])]}}],null,false,3091688985)}):_vm._e(),_vm._m(0),(
      _vm.$can(_vm.$roles.GROUP_NRQL) ||
      _vm.$can(_vm.$roles.GROUP_SMS) ||
      _vm.$can(_vm.$roles.GROUP_FINANCE_PRICING_UPLOAD) ||
      _vm.$can(_vm.$roles.GROUP_MY_INSIGHTS) ||
      _vm.$can(_vm.$roles.GROUP_SERVICE_MANAGEMENT_REPORTS) ||
      _vm.$can(_vm.$roles.GROUP_MSC) ||
      _vm.$can(_vm.$roles.GROUP_SALES_SHAREPOINT_XLSB_UPLOAD) ||
      _vm.$can(_vm.$roles.GROUP_SALES_DYNAMICS_CSV_UPLOAD)
    )?_c('router-link',{attrs:{"to":"/group/navigation-menu"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('li',{staticClass:"menu-item",class:{
        'menu-item-active': _vm.hasActiveChildren('/group')
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-network"}),_c('span',{staticClass:"menu-text"},[_vm._v("Group")])])])]}}],null,false,646514395)}):_vm._e(),(
      _vm.$can(_vm.$roles.BE_CRITICALS) ||
      _vm.$can(_vm.$roles.BE_AVAILABILITY_REPORTS) ||
      _vm.$can(_vm.$roles.BE_SERVICE_MANAGEMENT_REPORTS)
    )?_c('router-link',{attrs:{"to":"/be/navigation-menu"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon"},[_vm._v("BE")]),_c('span',{staticClass:"menu-text"},[_vm._v("Belgium")])])])]}}],null,false,3805095993)}):_vm._e(),_c('router-link',{attrs:{"to":"/bg/navigation-menu"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon"},[_vm._v("BG")]),_c('span',{staticClass:"menu-text"},[_vm._v("Bulgaria")])])])]}}])}),_c('router-link',{attrs:{"to":"/dk/navigation-menu"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon"},[_vm._v("DK")]),_c('span',{staticClass:"menu-text"},[_vm._v("Denmark")])])])]}}])}),_c('router-link',{attrs:{"to":"/nl/navigation-menu"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon"},[_vm._v("NL")]),_c('span',{staticClass:"menu-text"},[_vm._v("The Netherlands")])])])]}}])}),(_vm.$can(_vm.$roles.IO_ACCOUNTS) || _vm.$can(_vm.$roles.IO_CONTACTS))?_c('router-link',{attrs:{"to":"/integration-office/navigation-menu"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('li',{staticClass:"menu-item",class:{
        'menu-item-active': _vm.hasActiveChildren('/integration-office')
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-squares-4"}),_c('span',{staticClass:"menu-text"},[_vm._v("Integration Office")])])])]}}],null,false,1330052867)}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Teams")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])
}]

export { render, staticRenderFns }